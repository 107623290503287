import styles from './app.module.scss';
import Header from './components/header/header';
import AppRouter from './AppRouter';
import Footer from './components/footer/footer';

function App() {
  return (
    <div className={styles.app}>
      <Header />
      <div className={styles.container}>
        <AppRouter />
      </div>
      <Footer />
    </div>
  );
}

export default App;
