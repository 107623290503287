import React, { FC } from 'react';
import styles from './home.module.scss';
import Summary from '../summary/summary';

interface HomeProps {}

const Home: FC<HomeProps> = () => (
  <div className={styles.home}>
      <Summary />
  </div>
);

export default Home;
