import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom';
import Home from './components/home/home';
import About from './components/about/about';
import Projects from './components/projects/projects'
import Blog from './components/blog/blog'

function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>

                <Route path="/" element={ <Home />} />
                <Route path="/about" element={ <About />} />
                <Route path="/projects" element={ <Projects />} />
                <Route path="/blog" element={ <Blog />} />
                </Routes>

        </BrowserRouter>
    )
}

export default AppRouter