import React, { FC } from 'react';
import styles from './about.module.scss';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';

interface AboutProps {}


const About: FC<AboutProps> = () => (
  
  <div className={styles.about}>
    <div className={styles.academics}>
      <h3>Academics</h3>
      <Timeline>
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            <Typography variant="h6" component="span" className={styles.title}>
              Purdue Unviersity
            </Typography>
            <Typography className={styles.jobLocation}>West Lafayette, IN <p className={styles.date}>2019 - 2022</p></Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography className={styles.degreeTitle}>Aerospace Engineering, MSAE</Typography>
            <Typography>Autonomy & Control</Typography>
            <Typography>Astrodynamics & Space Applications</Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            <Typography variant="h6" className={styles.title} component="span">
              Case Western Reserve University
            </Typography>
            <Typography className={styles.jobLocation}>Cleveland, OH <p className={styles.date}>2012 - 2016</p></Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography className={styles.degreeTitle}>Aerospace Engineering, BSE</Typography>
            <Typography className={styles.degreeTitle}>Computer Science, BS</Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </div>

    <div className={styles.employment}>
      <h3>Employment</h3>
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6" className={styles.title} component="span">
              Lockheed Martin Aeronautics
            </Typography>
            <Typography className={styles.jobTitle}>Database Architect</Typography>
            <Typography className={styles.jobLocation}>Fort Worth, TX <p className={styles.date}>2016 - 2018</p></Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
          <Typography variant="h6" className={styles.title} component="span">
              Sikorsky, A Lockheed Company
            </Typography>
            <Typography className={styles.jobTitle}>Propulsion Flight Test Engineer</Typography>
            <Typography className={styles.jobLocation}>Patuxent River, MD <p className={styles.date}>2018 - 2019</p></Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
          <Typography variant="h6" className={styles.title} component="span">
              Aurora Flight Sciences, A Boeing Company
            </Typography>
            <Typography className={styles.jobTitle}>Embedded Aerospace Software Engineer</Typography>
            <Typography className={styles.jobLocation}>Manassas, VA<p className={styles.date}>2019 - 2022</p></Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
          </TimelineSeparator>
          <TimelineContent>
          <Typography variant="h6" className={styles.title} component="span">
              Rivian Automotive
            </Typography>
            <Typography className={styles.jobTitle}>Embedded Software Engineer</Typography>
            <Typography className={styles.jobLocation}>Palo Alto, CA<p className={styles.date}>2022 - Present</p></Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </div>

  </div>
);

export default About;
