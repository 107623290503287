import { FC } from 'react';
import styles from './footer.module.scss';
import {
  faGithub,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faGithub, faLinkedin, faEnvelope);

interface FooterProps { }

const Footer: FC<FooterProps> = () => (
  <div className={styles.footer}>
    <nav className={styles.Nav}>
      <a href="mailto:me@soliman.io">
        <FontAwesomeIcon className={styles.summaryIcon} icon={faEnvelope} />
      </a>
      <a target="_blank" rel="noreferrer" href="https://github.com/abdallahsoliman">
        <FontAwesomeIcon className={styles.summaryIcon} icon={faGithub} />
      </a>
      <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/abdallah-soliman-1885876b">
        <FontAwesomeIcon className={styles.summaryIcon} icon={faLinkedin} />
      </a>
    </nav>
  </div>
);

export default Footer;
